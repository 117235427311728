<template>
  <div class="home">
    <!-- 头部 -->
    <div class="header">
      <div class="header_top">
        <div class="tai_ico" @click="to_big_match"></div>
        <div class="loginType">
          <div class="loginType_ico"></div>
          <label @click="goLogin" style="cursor: pointer">{{
            loginMap[loginType]
          }}</label>
          <label
            v-show="loginType === 'login'"
            @click="logout"
            style="cursor: pointer"
            >退出登录</label
          >
        </div>
      </div>
      <div class="header_words">
        <div class="header_words_first">{{ this.$root.sys_title }}</div>
        <div class="header_words_secode">{{ this.$root.sys_sub_title }}</div>
      </div>
    </div>

    <!-- <div>因人数过多，导致服务器异常，故暂停比赛，比赛时间请关注本网站公告！ </div> -->

<!-- style="font-size:20px;color:red" -->
    <!-- <div >
      <img src="img/高中规则图.png" alt="" width="50%">
      </div> -->
    <br/><br/>

    <!-- 赛场入口 -->
    <div class="competition_inle" >
      <s-title :titleMsg="titleMsg1"></s-title>
      <div class="competition_class">
        <div class="class_box class_box_01" @click="to_train">
          <img src="/img/home_match_hu.png" />
          <p>训练场</p>
        </div>
        <div class="class_box class_box_02" @click="to_one_match">
          <img src="/img/home_match_hu.png" />
          <p>初中场</p>
        </div>
        <div class="class_box class_box_03" @click="to_two_match">
          <img src="/img/home_match_hu.png" />
          <p>高中场</p>
        </div>
      </div>
    </div>
    <!-- 赛场直播 -->
    <div class="competition_broadcast" v-show="false">
      <s-title
        :titleMsg="titleMsg2"
        v-show="
          homeData.match_vedio && homeData.match_vedio.length === 0
            ? false
            : true
        "
      ></s-title>
      <s-broadcast :broadcastMsg="homeData.match_vedio"></s-broadcast>
    </div>
    <!-- 赛事流程 -->
    <div class="competition_circuit" v-show="false">
      <s-circuit></s-circuit>
    </div>

    <!-- 培训事项 -->
    <div class="competition_result" >
      <s-title class="mar_bottom_60" :titleMsg="titleMsg4"></s-title>
      <!-- 结果 -->
      <s-train_rule></s-train_rule>
    </div>

    <!-- 比赛结果 -->
    <div class="competition_result" >
      <s-title class="mar_bottom_60" :titleMsg="titleMsg3"></s-title>
      <!-- 切换按钮 -->
      <div class="button-wrap">
        <div @click="changeResult(1)">初中</div>
        /
        <div @click="changeResult(2)">高中</div>
      </div>
      <!-- 结果 -->
      <s-result></s-result>
    </div>





    <!-- 底部 -->
    <footer class="footer">
      {{ this.$root.sys_foot_content }} |
      <a
        style="color: #ffffff"
        target="_blank"
        href="https://img.taimaker.com/bh_pro/参赛队原创性声明.pdf"
        >参赛队原创性申明</a
      >
    </footer>

    <!-- 原创弹窗 -->
    <el-dialog
      title="参赛队原创性申明"
      :visible.sync="dialogVisible"
      width="80%"
      :before-close="handleClose"
    >
      <span>
        <iframe
          src="https://img.taimaker.com/bh_pro/参赛队原创性声明.pdf"
          frameborder="0"
          style="width: 60vw; height: 60vh"
        ></iframe>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="agree_book">同意</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
import Title from "@/components/Title.vue";
import Broadcast from "./components/broadcast.vue";
import Circuit from "./components/circuit.vue";
import Result from "./components/result.vue";
import TrainRule from "./components/train_rule.vue";
// import { test } from "../../util/http/api";
import Oidc from "oidc-client";
import { quickLogin, homeGet } from "../../util/http/api";
const env = require("../../env/env");
//import env from "../../env/env";
//配置信息
var config = {
  authority: "https://account.shsunshine.org/",
  client_id: env.client_id,
  client_secret: env.client_secret,
  redirect_uri: env.redirect_uri,
  post_logout_redirect_uri: "https://ai.secsa.cn",
  response_type: "code",
  scope: "openid profile roles extoken_role",
  loadUserInfo: true,
  monitorAnonymousSession: true,
  revokeAccessTokenOnSignout: true,
  filterProtocolClaims: false,
  state: "abc",
  nonce: "xyz",
};
//Oidc.Log.logger = window.console;
Oidc.Log.logger = window.console;
Oidc.Log.level = Oidc.Log.ERROR;
//创建实例
let mgr = new Oidc.UserManager(config);

export default {
  name: "Home",
  components: {
    "s-title": Title,
    "s-broadcast": Broadcast,
    "s-circuit": Circuit,
    "s-result": Result,
    "s-train_rule": TrainRule,
  },
  data() {
    return {
      dialogVisible: false,
      loginType: "logout", // 判断是否登录 login:登录 logout: 未登录
      homeData: {}, //首页数据
      loginMap: {
        login: "",
        logout: "未登录",
        //logout: "",
      },
      titleMsg1: {
        title: "赛场入口",
        count: "这里有用来熟悉赛场的训练场，初中组、高中组的比赛场",
      },
      titleMsg2: {
        title: "赛事直播",
        count: "这里有正在激烈对抗的赛事实时直播",
      },
      titleMsg3: {
        title: "比赛结果公布",
        count: "经过同学们激烈的比赛，可以在这里领回属于自己的荣誉",
      },
      titleMsg4: {
        title: "培训安排",
        count: "这里展示我们培训的课程视频以及相关答疑渠道",
      },
      parmes_code: "",
      parmes_scope: "",
      parmes_session_state: "",
    };
  },
  created() {},
  mounted() {
    this.getAllParmasInfo(); //获取url中的参数
    //console.log("通过code《" + this.parmes_code + "》获取用户信息");
    if (this.parmes_code) {
      //登录过之后无需登录直接获取
      this.goLogin();
    } else {
      if (
        !localStorage.getItem("loginMasg") ||
        localStorage.getItem("loginMasg") == ""
      ) {
        this.loginMyPlatform();
      } else {
        this.loginType = "login";
        let userData = JSON.parse(localStorage.getItem("ky_login_info"));
        this.loginMap.login = userData.profile.real_name;
      }

      // console.log("uuu" + userData.profile.name);
      // this.loginMyPlatform();
    }
    this.get_home();
  },

  methods: {
    agree_book() {
      localStorage.setItem("agree_book", "1");
      this.dialogVisible = false;
      location.reload();
      this.$router.go(0);
    },
    to_big_match() {
      location.href = "https://ai.secsa.cn";
    },
    //获取url中的参数
    getAllParmasInfo() {
      this.parmes_code = this.getQueryVariable("code");
      this.parmes_session_state = this.getQueryVariable("session_state");
      this.parmes_scope = this.getQueryVariable("scope");
    },
    //获取参数函数
    getQueryVariable(variable) {
      var query = window.location.search.substring(1);
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      return false;
    },
    //登录自己的平台
    loginMyPlatform() {
      let that = this;
      setTimeout(() => {
        let userInfo = localStorage.getItem("ky_login_info");
        if (userInfo) {
          let params = "";
          this.$http
            .run(quickLogin + "?keyi_parames=" + userInfo, params, {
              type: "get",
              loginApi: true,
            })
            .then((res) => {
              let agree_book_val = localStorage.getItem("agree_book");
              if (agree_book_val != "1") {
                this.dialogVisible = true;
              }
              that.loginType = "login";
              localStorage.setItem("loginMasg", res.data.bh_token);
              that.loginMap.login = res.data.ky_user_info.real_name;

              //axios.defaults.headers.token
              // let userData = JSON.parse(localStorage.getItem("ky_login_info"));
              // this.loginMap.login = userData.profile.real_name;
            });
        }
      }, 1000);
    },
    //获取首页数据
    get_home() {
      let params = "";
      let that = this;
      this.$http
        .run(homeGet, params, {
          type: "get",
          loginApi: true,
        })
        .then((res) => {
          that.homeData = res.data;
        });
    },

    // 去训练场
    to_train() {
      // this.$message({
      //           message: "即将正式比赛，训练场已关闭",
      //           type: "warning",
      //         });
      this.$router.push({
        path: "/gamemain",
        query: {
          code: "Test-Area",
        },
      });
      this.routerRefresh();
    },
    // 去比赛场 （初中）
    to_one_match() {
      this.$router.push({
        path: "/meMatch",
      });
      this.routerRefresh();
    },
    // 去比赛场 （高中）
    to_two_match() {
      this.$router.push({
        path: "/meMatch",
      });
      this.routerRefresh();
    },

    //点击右上角图标
    goLogin() {
      config.scope = "openid profile roles extoken_role";
      if (this.loginType === "login") {
        this.$router.push({
          path: "/person",
        });
        this.routerRefresh();
      } else {
        this.login();
      }
    },
    //登录
    login() {
      mgr.events.addUserLoaded(function () {
        this.showTokens();
      });
      mgr.events.addUserUnloaded(function () {
        this.showTokens();
      });
      window.location.hash = decodeURIComponent(window.location.hash);
      if (window.location.hash) {
        this.handleCallback();
      }
      mgr.signinRedirect();
    },
    //退出登录
    logout() {
      localStorage.setItem("agree_book", "");
      localStorage.setItem("loginMasg", "");
      //localStorage.setItem("ky_login_info", "");
      localStorage.clear();
      this.logout = false;
      mgr.signoutRedirect();
    },
    //去登陆自己的平台
    toRequestMyLogin(data) {
      if (data && typeof data === "string") {
        try {
          data = JSON.parse(data);
        } catch (e) {
          console.log(e);
        }
      }
      if (data && typeof data !== "string") {
        data = JSON.stringify(data, null, 2);
      }
    },
    showTokens() {
      mgr.getUser().then(function (user) {
        if (user) {
          this.$message({ message: user, type: "success" });
          this.toRequestMyLogin(user);
        } else {
          //没有请求到Tokens
          this.$message.error("登录失败");
        }
      });
    },
    handleCallback() {
      //回调数据
      mgr.signinRedirectCallback().then(
        function () {
          var hash = window.location.hash.substr(1);
          var result = hash.split("&").reduce(function (result, item) {
            var parts = item.split("=");
            result[parts[0]] = parts[1];
            return result;
          }, {});
          this.showTokens();
          window.history.replaceState(
            {},
            window.document.title,
            window.location.origin + window.location.pathname
          );
        },
        function (error) {
          //回调的错误信息
          //console.error(error);
        }
      );
    },
    // 切换排行
    changeResult(level) {
      if (level == 1) {
        console.log("初中");
      } else if (level == 2) {
        console.log("高中");
      }
    },
  },
};
</script>
<style scoped lang="scss">
.header {
  /*min-width: 1440px;*/
  min-width: 1024px;
  /* width: 100%; */
  height: 570px;
  /* cursor: pointer; */
  /* background-color: turquoise; */
  background-image: url("/img/home_top_back.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-bottom: 60px;
}

.header_top {
  /*width: 1136px;*/
  width: 900px;
  margin: 0 auto;
  /* padding-top: 18px; */
  height: 60px;
  overflow: hidden;
  position: relative;
}

.header_words {
  width: 900px;
  margin: 100px auto;
  color: #ffffff;
  font-family: "Helvetica Neue", Helvetica, PingFang SC, "Hiragino Sans GB",
    "Microsoft YaHei", Arial, sans-serif;
  font-weight: 600;
}

.header_words_first {
  font-size: 40px;
  /* line-height: 84px; */
  margin-bottom: 32px;
}

.header_words_secode {
  font-size: 24px;
  float: right;
  margin-right: 200px;
  /* line-height: 33px; */
}

.tai_ico {
  width: 122px;
  height: 42px;
  background-image: url("/img/tai_ico.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  /* float: left; */
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.loginType {
  /* overflow: hidden; */
  line-height: 24px;
  font-size: 14px;
  font-family: "Helvetica Neue", Helvetica, PingFang SC, "Hiragino Sans GB",
    "Microsoft YaHei", Arial, sans-serif;
  font-weight: 400;
  color: #ffffff;
  /* float: right; */
  cursor: pointer;
  /* padding-right: 2px; */
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.loginType_ico {
  width: 24px;
  height: 24px;
  /* background: url("/login_ico.png"); */
  background-image: url("/img/login_ico.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  float: left;
  margin-right: 8px;
  /* E:\MY-DOM\ice-curling\ice_curling\src\assets\play_ico.png */
  /* E:\MY-DOM\ice-curling\ice_curling\src\views\home\home.vue */
}

.competition_inle {
  width: 900px;
  margin: 0 auto 80px;
}

.competition_class {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.class_box {
  width: 260px;
  height: 161px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
  text-align: center;
}

.class_box img {
  margin-top: 20px;
}

.class_box p {
  margin-top: 10px;
  font-size: 25px;
  font-weight: 400;
  color: #ffffff;
}

.class_box_01 {
  background-image: url("/img/home_match_bg_01.png");
}

.class_box_02 {
  background-image: url("/img/home_match_bg_02.png");
}

.class_box_03 {
  background-image: url("/img/home_match_bg_03.png");
}

.class_box:hover {
  transform: scale(1.05);
}

.competition_broadcast,
.competition_result {
  width: 900px;
  margin: 0 auto 80px;
  position: relative;
  top: 0;
  left: 0;
  .button-wrap {
    width: 100px;
    height: 30px;
    position: absolute;
    top: 8px;
    right: 180px;
    color: skyblue;
    display: flex;
  }
}

.competition_circuit {
  margin-bottom: 80px;
}

.footer {
  min-width: 1024px;
  height: 143px;
  font-size: 16px;
  font-family: "Helvetica Neue", Helvetica, PingFang SC, "Hiragino Sans GB",
    "Microsoft YaHei", Arial, sans-serif;
  font-weight: 500;
  color: #ffffff;
  line-height: 143px;
  background-image: url("/img/home_bottom_back.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.mar_bottom_60 {
  margin-bottom: 100px;
}
</style>
