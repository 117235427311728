export const quickLogin = "/bh-api/api_font/login"; // 快速登录
export const identify = "/bh-api/api_font/get_identify"; // 获取我的身份信息
export const allGroupList = "/bh-api/api_font/group_info2"; // 获取所有队伍列表
export const reportReq = "/bh-api/api_font/report"; //报么名
export const reportInfoReq = "/bh-api/api_font/report_info"; //报名信息
export const reportCreateGroup = "/bh-api/api_font/create_group"; //创建队伍
export const topGroup = "/bh-api/api_font/top_group"; //创建队伍
export const sureGroup = "/bh-api/api_font/sure_group"; //确认队伍
export const getMyGroupList = "/bh-api/api_font/group_info"; //获取自己创建的队伍信息
export const trainListGet = "/bh-api/api_font/train_list"; //获取训练记录
export const matchListGet = "/bh-api/api_font/match_info_list"; //获取我的比赛信息
export const homeGet = "/bh-api/api_font/home"; //获取训练记录
/**赛场部分api接口 */
export const api_race_score_statistics1 = "/bh-api/api_font/race_score_statistics1";   //高中组数据体
export const api_race_get_info = "/bh-api/api_font/race_get_info"; //获取赛场数据
export const api_race_code_analysis = "/bh-api/api_font/race_code_analysis"; //代码解析
export const api_race_data_resercivce = "/bh-api/api_font/race_data_resercivce"; //击打数据回传
export const api_race_score_statistics =
  "/bh-api/api_font/race_score_statistics"; //得分统计回传

export const race_data_postion4 = "/bh-api/api_font/race_data_postion4";    //上传4壶代码

export const api_race_collide_resercivce = "/bh-api/api_font/race_collide_resercivce"; //碰撞回传

export const logout = "/bh-api/account/logout"; // 退出登录
