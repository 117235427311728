<template>
  <div class="circuit_box">
    <div class="circuit_box_title">赛事流程</div>
    <div class="circuit_box_line">
      <div class="circuit_box_each circuit_box_join">
        <div class="point" @click="to_url(0)">
          <div class="point_title">
            <!-- {{ matchStage[0] ? matchStage[0].stage_name : "" }} -->
            在线培训
          </div>
          <div
            class="point_box is_point_fock"
            :class="time === '0' ? 'is_point_fock' : 'no_point_fock'"
          ></div>
          <!-- <div class="point_time">{{ matchStage[0].stage_time }}</div> -->
          <div class="point_time">
            <!-- {{ matchStage[0] ? matchStage[0].stage_time : "" }} -->
            2021/7-8月
          </div>
        </div>
      </div>
      <div class="circuit_box_each P_a_l_257px">
        <div class="point" @click="to_url(1)">
          <div class="point_title">
            <!-- {{ matchStage[1] ? matchStage[1].stage_name : "" }} -->
            报名指南
          </div>
          <div
            class="point_box"
            :class="time === '1' ? 'is_point_fock' : 'no_point_fock'"
          ></div>
          <!-- <div class="point_time">{{ matchStage[1].stage_time }}</div> -->
          <div class="point_time">
            <!-- {{ matchStage[1] ? matchStage[1].stage_time : "" }} -->
            2021/9/1
          </div>
        </div>
      </div>
      <div class="circuit_box_each P_a_l_546px">
        <div class="point" @click="to_url(2)">
          <div class="point_title">
            <!-- {{ matchStage[2] ? matchStage[2].stage_name : "" }} -->
            赛事报名
          </div>
          <div
            class="point_box"
            :class="time === '2' ? 'is_point_fock' : 'no_point_fock'"
          ></div>
          <!-- <div class="point_time">{{ matchStage[2].stage_time }}</div> -->
          <div class="point_time">
            <!-- {{ matchStage[2] ? matchStage[2].stage_time : "" }} -->
            2021/9/8
          </div>
        </div>
      </div>
      <div class="circuit_box_each P_a_r_257px">
        <div class="point" @click="to_url(3)">
          <div class="point_title">
            <!-- {{ matchStage[3] ? matchStage[3].stage_name : "" }} -->
            初赛
          </div>
          <div
            class="point_box"
            :class="time === '3' ? 'is_point_fock' : 'no_point_fock'"
          ></div>
          <!-- <div class="point_time">{{ matchStage[3].stage_time }}</div> -->
          <div class="point_time">
            <!-- {{ matchStage[3] ? matchStage[3].stage_time : "" }} -->
            2021 9/19-20
          </div>
        </div>
      </div>
      <div class="circuit_box_each P_a_r_0px">
        <div class="point" @click="to_url(4)">
          <div class="point_title">
            <!-- {{ matchStage[4] ? matchStage[4].stage_name : "" }} -->
            决赛
          </div>
          <div
            class="point_box"
            :class="time === '4' ? 'is_point_fock' : 'no_point_fock'"
          ></div>
          <!-- <div class="point_time">{{ matchStage[4].stage_time }}</div> -->
          <div class="point_time">
            <!-- {{ matchStage[4] ? matchStage[4].stage_time : "" }} -->
            2021/9/25
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { homeGet } from "../../../util/http/api";
export default {
  data() {
    return {
      time: "1",
      matchStage: [],
    };
  },

  created() {
    this.get_home();
  },
  methods: {
    to_url(url_str) {
      window.open(this.matchStage[url_str].stage_book_url);
    },
    //获取首页数据
    get_home() {
      let params = "";
      let that = this;
      this.$http
        .run(homeGet, params, {
          type: "get",
          loginApi: true,
        })
        .then((res) => {
          //console.log(res);

          that.matchStage = res.data.match_stage;
          for (let index = 0; index < that.matchStage.length; index++) {
            //时间转换
            let date = new Date(that.matchStage[index].stage_time);
            let timeStamps = Date.parse(date);
            //console.log(timeStamps + "====" + new Date().getTime());
            if (new Date().getTime() > timeStamps) {
              that.time = index + "";
            }
            //格式化时间
            that.matchStage[index].stage_time = that.matchStage[
              index
            ].stage_time.substring(
              0,
              that.matchStage[index].stage_time.lastIndexOf(":")
            );
          }
        });
    },
  },
};
</script>

<style scoped>
.circuit_box {
  /* width: 100%; */
  min-width: 1024px;
  padding-top: 40px;
  padding-bottom: 122px;
  /* height: 3; */
  /* height: 352px; */
  background-image: url("/img/circuit_back.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.circuit_box_title {
  font-size: 32px;
  font-family: "Helvetica Neue", Helvetica, PingFang SC, "Hiragino Sans GB",
    "Microsoft YaHei", Arial, sans-serif;
  font-weight: 600;
  color: #ffffff;
  line-height: 45px;
  margin: 0 auto 144px;
}
.circuit_box_line {
  width: 800px;
  height: 1px;
  background: #ffffff;
  border-radius: 6px;
  margin: 0 auto;
  position: relative;
}
.circuit_box_each {
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
}
.circuit_box_node {
  left: 200px;
}
.point {
  position: relative;
  width: 25px;
  height: 25px;
  /* background-color: rgba(255, 255, 255, 0.4); */
  border-radius: 50%;
}
.point_box {
  width: 25px;
  height: 25px;
  /* background: #ffffff; */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: 10;
  opacity: 10;
  position: absolute;
  cursor: pointer;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  /* border-radius: 50%; */
}
.is_point_fock {
  background-image: url("/img/is_point_fock.png");
}
.no_point_fock {
  background-image: url("/img/no_point_fock.png");
}
.point_title {
  font-size: 24px;
  font-family: "Helvetica Neue", Helvetica, PingFang SC, "Hiragino Sans GB",
    "Microsoft YaHei", Arial, sans-serif;
  font-weight: 600;
  color: #ffffff;
  position: absolute;
  top: -40px;
  left: 50%;
  cursor: pointer;
  transform: translateX(-50%);
  white-space: nowrap;
}
.point_time {
  font-size: 16px;
  font-family: "Helvetica Neue", Helvetica, PingFang SC, "Hiragino Sans GB",
    "Microsoft YaHei", Arial, sans-serif;
  font-weight: 400;
  color: #ffffff;
  white-space: nowrap;
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
}
.P_a_l_257px {
  left: 180px;
}
.P_a_l_546px {
  left: 380px;
}
.P_a_r_257px {
  right: 180px;
}
.P_a_r_0px {
  right: 0;
  transform: translateX(50%) translateY(-50%);
}
</style>
