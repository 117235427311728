<template>
  <div id="app" ref="main">
    <router-view />
  </div>
</template>

<script>
export default {
  watch: {
    $route() {
      let main_page = this.$refs.main;
      main_page.scrollTo(0, 0);
    },
  },
  mounted(){
    let userAgent = navigator.userAgent; //取得浏览器的userAgent字符串 
    let isEdge = userAgent.indexOf("Edge") > -1; //判断是否IE的Edge浏览器
    let isFF = userAgent.indexOf("Firefox") > -1; //判断是否Firefox浏览器 
    let isChrome = userAgent.indexOf("Chrome") > -1 && userAgent.indexOf("Safari") > -1 && !isEdge; //判断Chrome浏览器

    try {
        if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
            document.getElementById('app').style.display = 'none';
            document.write('<h1>浏览器限制(请使用PC浏览器访问)</h1><h3>为保证比赛的稳定性请使用PC端浏览器访问该项比赛！<br/>推荐《谷歌Chrome》或《火狐Firefox》</h3>')
        } else {
            //alert("22222")
            console.log("-")
        }
    } catch (e) {
      console.log("浏览器判断错误")
      //alert("error")
    }



    if(!isChrome&&!isFF){
        document.getElementById('app').style.display = 'none';
        document.write('<h1>浏览器限制</h1><h3>为保证比赛的稳定性，推荐使用《谷歌Chrome》或《火狐Firefox》浏览器访问该项比赛！</h3>')
    }else{
        console.log("浏览器Ready！")
    }
  }
};
</script>

<style>
body,
div,
span,
img,
a,
ul,
li,
i {
  margin: 0;
  padding: 0;
  font-family: PingFang, sans-serif;
}
img {
  font-size: 0;
}
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /* color: #2c3e50; */
  height: 100%;
}
html,
body,
#app {
  height: 100%;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
