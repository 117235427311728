<template>
  <div class="title">
    <div class="title_name">{{ titleMsg.title }}</div>
    <div class="title_count">{{ titleMsg.count }}</div>
  </div>
</template>

<script>
export default {
  props: {
    titleMsg: {
      type: Object,
      default: function () {
        return {};
      },
    },
    data() {
      return {};
    },
  },
};
</script>

<style scoped>
.title {
  margin-bottom: 40px;
}
.title_name {
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  color: #313131;
  margin-bottom: 16px;
}
.title_count {
  font-size: 16px;
  font-weight: 400;
  color: #929292;
}
</style>
