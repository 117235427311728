/**
 * 生产环境-配置清单
 * @type {{server_env_name: string, server_env_desc: string, server_info: {port: string}, mysql_info: {name: string, desc: string, host: string, port: string, password: string}, redis_info: {name: string, desc: string, host: string, port: string, password: string}, welfare: string}}
 */
module.exports = {
  server_env_name: "dev",
  baseURL: "https://bh.taimaker.com",
  socket_addr: "https://bh.taimaker.com",
  redirect_uri: "https://bh.taimaker.com/auth_success",
  client_id: "szbh_client",
  client_secret: "ict*GBiNprd%gj+j",
};
