<template>
  <div class="result_box">
    <div class="result_box_each border_r_16px_l float_left">
      <div class="result_box_each_ico no_second"></div>
      <!-- <div class="prize_box_btn secone_box_btn">二等奖</div>
      <div class="result_box_each_team">xxxxxx队：98分</div> -->
    </div>
    <div class="result_box_each box_show height_496 position_abo">
      <div class="result_box_each_ico no_first"></div>
      <!-- <div class="prize_box_btn first_box_btn">一等奖</div>
      <div class="result_box_each_team">xxxxxx队：98分</div> -->
    </div>
    <div class="result_box_each border_r_16px_l float_right">
      <div class="result_box_each_ico no_third"></div>
      <!-- <div class="prize_box_btn third_box_btn">三等奖</div> -->
      <!-- <div class="result_box_each_team">xxxxxx队：98分</div>
      <div class="result_box_each_team">xxxxxx队：98分</div>
      <div class="result_box_each_team">xxxxxx队：98分</div> -->
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.result_box {
  background: #ffffff;
  box-shadow: 0px 16px 30px 0px rgba(14, 93, 197, 0.1);
  border-radius: 16px;
  position: relative;
  height: 436px;
  /* display: flex;
  justify-content: space-between; */
}
.result_box_each {
  width: 270px;
  height: 336px;
  background-color: #ffffff;
}
.result_box_each_ico {
  width: 178px;
  height: 216px;
  margin: 0 auto 6px;
  /* background-image: url("/img/class_middle.png"); */
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.no_first {
  background-image: url("/img/no_first.png");
}
.no_second {
  background-image: url("/img/no_second.png");
}
.no_third {
  background-image: url("/img/no_third.png");
}
.prize_box_btn {
  width: 260px;
  height: 60px;
  border-radius: 30px;
  font-size: 32px;
  font-family: "Helvetica Neue", Helvetica, PingFang SC, "Hiragino Sans GB",
    "Microsoft YaHei", Arial, sans-serif;
  font-weight: 500;
  line-height: 60px;
  margin: 0 auto 32px;
}
.first_box_btn {
  background: #fddc3a;
  color: #e3a815;
}
.secone_box_btn {
  background: #d3d3d3;
  color: #f0f0f0;
}
.third_box_btn {
  background: #e19449;
  color: #ffdca6;
}
.result_box_each_team {
  font-size: 22px;
  font-family: "Helvetica Neue", Helvetica, PingFang SC, "Hiragino Sans GB",
    "Microsoft YaHei", Arial, sans-serif;
  font-weight: 500;
  color: #333333;
  line-height: 40px;
}
.border_r_16px_l {
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}
.border_r_16px_l {
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}
.box_show {
  box-shadow: 0px 0px 30px 0px rgba(14, 93, 197, 0.1);
}
.height_496 {
  height: 496px;
}
.position_abo_left {
}
.float_left {
  float: left;
}
.float_right {
  float: right;
}
.position_abo {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}
</style>
