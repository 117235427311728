import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/home/home.vue";
//VueRouter.RouterMode = "history";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    //登录成功回调页面
    path: "/auth_success",
    name: "AuthSuccess",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/person/auth_success.vue"
      ),
  },
  {
    //个人中心
    path: "/person",
    name: "Person",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/person/person.vue"),
  },
  {
    //比赛结束页面
    path: "/gameover",
    name: "Gameover",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/gameover/gameover.vue"),
  },
  {
    //比赛主界面-测试场
    path: "/gameMain",
    name: "Gamemain",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/gamemain/gamemain.vue"),
  },
  //
  // {
  //   //比赛主界面
  //   path: "/gameMainRaceHeight",
  //   name: "Gamemainraceheight",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "about" */ "../views/gamemain/gamemain_race_height.vue"
  //     ),
  // },
  {
    //比赛主界面
    path: "/gameMainRace",
    name: "Gamemainrace",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/gamemain/gamemain_race.vue"
      ),
  },
  {
    //比赛主界面
    path: "/meMatch",
    name: "Mematch",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/person/me_match.vue"),
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

export default router;
