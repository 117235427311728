import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import http from "./util/http/http.js";
import "./common/font/font.css";
import SocketIO from "socket.io-client";
import VueSocketIO from "vue-socket.io";

Vue.use(ElementUI);

const env = require("./env/env");

//socket的使用
const socketOptions = {
  autoConnect: false, // 自动连接
};
// 注册
Vue.use(
  new VueSocketIO({
    debug: false, // debug调试，生产建议关闭
    //https://bh.taimaker.com
    //http://localhost:9087
    connection: SocketIO(env.socket_addr, socketOptions),
    store, // 如果没有使用到store可以不用写
  })
);

Object.defineProperty(Vue.prototype, "$http", { value: http });
// console.log(Object.defineProperty);
// console.log(Vue.prototype);
Vue.config.productionTip = false;

new Vue({
  // 这里为全局监听socket事件消息，监听函数这里只写了一点，其实很有很多事件。
  sockets: {
    connecting() {
      console.log("正在连接");
    },
    disconnect() {
      console.log("Socket 断开");
    },
    connect_failed() {
      console.log("连接失败");
    },
    connect() {
      console.log("socket connected");
    },
  },
  router,
  store,
  data: function () {
    return {
      api_base_url: "",
      img_base_url: "https://img.taimaker.com/",
      sys_title: "首届长三角青少年人工智能奥林匹克挑战赛",
      sys_sub_title: "嘉年华—虚拟冰壶活动",
      sys_foot_content: "Copyright@ 2021 bh.taimaker.com All Rights Reserved.",
      socket_base_url: env.socket_addr, //socket地址
      //业务需求临时配置
      http_token: "a88f2d92f6a2b2cc88d262418173eb45",
    };
  },
  render: (h) => h(App),
}).$mount("#app");
