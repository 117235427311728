import axios from "axios";
const env = require("../../env/env");
// axios.interceptors.request.use(config => {
//   config.headers["bh-token"] = localStorage.getItem("loginMasg");return config
// }, error => {return Promise.reject(error)
// })
const instance = axios.create({
  baseURL: env.baseURL,
  headers: {
    "bh-token": localStorage.getItem("loginMasg"),
    Content_type: "application/json",
  },
});
const httpAxios = {
  post(api, params) {
    return new Promise((resolve, reject) => {
      instance
        .post(api, params, {
          headers: {
            "content-type": "application/json",
            "bh-token": localStorage.getItem("loginMasg"),
          },
          data: params,
        })
        //.headers({"bh-token":localStorage.getItem("loginMasg"),Content_type: "application/json"})
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  get(api, params) {
    return new Promise((resolve, reject) => {
      //console.dir("token" + localStorage.getItem("loginMasg"));
      instance
        .get(api, params, {
          headers: {
            "content-type": "application/json",
            "bh-token": localStorage.getItem("loginMasg"),
          },
        })
        //.headers({"bh-token":localStorage.getItem("loginMasg"),Content_type: "application/json"})
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
export { httpAxios };
