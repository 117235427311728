import { httpAxios } from "./httpAxios";
import { Message } from "element-ui";
export default {
  run(api, params, option = {}) {
    // if (!option.loginApi) {
    //   //params.bh_token = localStorage.getItem("loginMasg");
    // }
    return new Promise((resolve, reject) => {
      let type = option.type;
      // console.dir(httpAxios);
      //httpAxios.headers["bh-token"] = localStorage.getItem("loginMasg");
      httpAxios[type](api, params)
        .then((res) => {
          if (res.data.code === "msg_100") {
            resolve(res.data);
            return;
          } else if (res.data.code === "err_101") {
            //未登录
            //localStorage.removeItem("ky_login_info");
            localStorage.removeItem("loginMasg");
          } else {
            Message.error(res.data.msg);
          }
          // Message.error(res.data.msg);
        })
        .catch((err) => {
          reject(err);

          // Message.error("网络异常请稍后重试");
        });
    });
  },
};
