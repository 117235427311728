<template>
  <div class="broadcast">
    <div
      class="broadcast_each"
      v-for="(item, index) in broadcastMsg"
      :key="index"
      @click="toDetail(index)"
    >
      <div
        class="broadcast_each_img"
        :class="index % 2 == 0 ? 'woman_line_back' : 'man_line_back'"
      >
        <div class="broadcast_each_ico"></div>
      </div>
      <div class="broadcast_troops">
        <p>{{ item.group_a_name }} VS {{item.group_b_name}}</p>
        <p>{{item.group_small_name}}</p>
        <p>{{item.match_status=='0'?"未开始":item.match_status=="2"?"已结束":"正在比赛"}}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    broadcastMsg: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  methods: {
    toDetail(index) {
      let code_m = this.broadcastMsg[index].code;
      let groud_m = this.broadcastMsg[index].match_groud;
      this.$router.push({
        path: "/gamemainrace",
        query: {
          code: code_m,
          groud: groud_m
        },
      });
      this.routerRefresh();
    },
  },
};
</script>

<style scoped>
.broadcast {
  /* height: 475px; */
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: space-between;
}

.broadcast_each {
  width: 266px;
  min-height: 224px;
  height: 290px;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #e3ecf2;
  cursor: pointer;
}

.broadcast_each:nth-child(5n + 1) {
  margin-bottom: 25px;
}

.broadcast_each:hover {
  box-shadow: 0px 16px 30px 0px rgba(14, 93, 197, 0.1);
}

.broadcast_each_img {
  width: 100%;
  height: 165px;
  position: relative;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.man_line_back {
  background-image: url("/img/man_line_back.png");
}

.woman_line_back {
  background-image: url("/img/man_line_back.png");
}

.broadcast_each_ico {
  width: 80px;
  height: 80px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-image: url("/img/play_ico.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.broadcast_troops {
  font-size: 13px;
  font-weight: 500;
  color: #333333;
  height: auto;
  text-align: center;
}

.broadcast_troops :hover {
  transform: scale(1.05);
}
</style>
