<template>
  <div class="result_box">
    <el-collapse v-model="activeNames" @change="handleChange">
      <el-collapse-item title="  第一课 《虚拟冰壶中的数学》" name="1">
        <div class="content_div">
          培训内容：平面坐标系、极坐标系、坐标系转换、ρ、θ计算、矢量分解等
        </div>
        <div class="content_div">培训时间：2021.7.15 周四 16：00-17：00</div>
        <a
          class="content_div"
          target="_blank"
          href="https://ke.qq.com/webcourse/3480785/103619122#from=800021724&lite=1"
          >直播地址</a
        >
        |
        <a
          class="content_div"
          target="_blank"
          href="https://ke.qq.com/webcourse/3480785/103619122#taid=39138245&lite=1&vid=3701925920967944293"
          >回放地址</a
        >
        <!-- <a>回放地址</a> -->
      </el-collapse-item>
      <el-collapse-item title="  第二课 《虚拟冰壶中的物理》" name="2">
        <div class="content_div">
          培训内容：牛顿三大定律、能量守恒、动量守恒、摩擦系数、弹性碰撞、加速度等
        </div>
        <div class="content_div">培训时间：2021.7.22 周四 16：00-17：00</div>
        <a
          class="content_div"
          target="_blank"
          href="https://ke.qq.com/webcourse/3480831/103619168#from=800021724&lite=1"
          >直播地址</a
        >
        |
        <a
          class="content_div"
          target="_blank"
          href="https://ke.qq.com/webcourse/3480831/103619168#taid=39191596&lite=1&vid=3701925921348548836"
          >回放地址</a
        >
      </el-collapse-item>
      <el-collapse-item title="  第三课 《虚拟冰壶中的Python编程》" name="3">
        <div class="content_div">
          培训内容：Python编程调用的库、投掷函数、获知位置的函数、优化代码等
        </div>
        <div class="content_div">培训时间：2021.7.29 周四 16：00-17：00</div>
        <a
          class="content_div"
          target="_blank"
          href="https://ke.qq.com/webcourse/3480832/103619169#from=800021724&lite=1"
          >直播地址</a
        >
        |
        <a
          class="content_div"
          target="_blank"
          href="https://ke.qq.com/webcourse/3480832/103619169#taid=39250695&lite=1&vid=3701925921726983109"
          >回放地址</a
        >
      </el-collapse-item>
      <el-collapse-item title="  第四课 《虚拟冰壶中的策略》" name="4">
        <div class="content_div">
          培训内容：观摩各种冰壶击打规律、学习如何保护、如何设置障碍、如何设置击打方式等
        </div>
        <div class="content_div">培训时间：2021.8.5 周四 16：00-17：00</div>
        <a
          class="content_div"
          target="_blank"
          href="https://ke.qq.com/webcourse/3480833/103619170#from=800021724&lite=1"
          >直播地址</a
        >
        |
        <a
          class="content_div"
          target="_blank"
          href="https://ke.qq.com/webcourse/3480833/103619170#from=800021724&taid=39379845&vid=3701925922102295491&lite=1"
          >回放地址(上)</a
        >
        <a
          class="content_div"
          target="_blank"
          href="https://ke.qq.com/webcourse/3480833/103619170#from=800021724&taid=39380517&vid=3701925922103292059&lite=1"
          >回放地址(下)</a
        >
      </el-collapse-item>
      <el-collapse-item title="  第五课 《虚拟冰壶中的竞技》" name="5">
        <div class="content_div">
          培训内容：讲解在线比赛规则、介绍团队合作、组织和领导等配合
        </div>
        <div class="content_div">培训时间：2021.8.12 周四 16：00-17：00</div>
        <a
          class="content_div"
          target="_blank"
          href="https://ke.qq.com/webcourse/3480835/103619172#from=800021724&lite=1"
          >直播地址</a
        >
        |
        <a
          class="content_div"
          target="_blank"
          href="https://ke.qq.com/webcourse/3480835/103619172#taid=39614927&lite=1&vid=3701925922504614625"
          >回放地址</a
        >
      </el-collapse-item>
      <el-collapse-item title="  备注 《相关说明》" name="6">
        <div class="content_div">1.每次课程时长在45~60分钟。</div>
        <div class="content_div">
          2.本次培训采用线上方式进行，采用腾讯课堂平台，参与培训学生如法参加直播教学，培训后可在虚拟冰壶活动网站观看回放。
        </div>
        <div class="content_div">3.活动技术交流邮箱：tim@taimaker.com</div>
        <div class="content_div">
          （本邮箱仅咨询虚拟冰壶项目技术性问题，工作时间：周一~周五9:00-17:00）
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeNames: ["1", "2", "3", "4", "5", "6"],
    };
  },
  methods: {
    handleChange(val) {
      console.log(val);
    },
  },
};
</script>

<style scoped>
.result_box {
  background: #ffffff;
  box-shadow: 0px 16px 30px 0px rgba(14, 93, 197, 0.1);
  border-radius: 16px;
  position: relative;
  padding: 10px;
  text-align: left;
  height: auto;
}
.title_div {
  font-size: 25px;
}
.content_div {
  font-size: 18px;
}
</style>
